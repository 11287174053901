import { CatalogicalSearchBox } from "common/components/CatalogicalSearchBox";
import { SearchBox } from "common/components/SearchBox";
import { CATALOG_PROVIDER } from "config";
import React, { FC } from "react";

export interface ISearchBoxProps {
  /**
   * The width of the Search Box
   */
  width?: string;
  /**
   * The query string
   */
  queryText?: string | null;
}

const SearchBoxFactory: FC<ISearchBoxProps> = (props: ISearchBoxProps) => {
  if (CATALOG_PROVIDER === "catalogical") {
    return <CatalogicalSearchBox queryText={props.queryText} width={props.width} />;
  } else {
    return <SearchBox queryText={props.queryText} width={props.width} />;
  }
};

export default SearchBoxFactory;
