import "./style.scss";

import { Col, Container, Row, Spacer, View } from "@amzn/stencil-react-components/layout";
import { Hr } from "@amzn/stencil-react-components/layout";
import { WithModal } from "@amzn/stencil-react-components/modal";
import { Text } from "@amzn/stencil-react-components/text";
import parse from "html-react-parser";
import React, { FunctionComponent, useEffect, useState } from "react";
import { DetailsPageProps } from "view/Page/DetailsPage";

import { CatalogItem, ItemStatus, Version } from "../../types/catalog-types";
import { getString } from "../../uistringlabels/uiStringUtils";
import { CustomerFeedback, CustomerFeedbackAlignment, CustomerFeedbackIconPosition } from "../CustomerFeedback";
import { OrderNow } from "../OrderNow";
import { SelectChildSku } from "../SelectChildSku";
import { SubmitOrderModal } from "../SubmitOrderModal";

export const ProductDetailsPage: FunctionComponent<DetailsPageProps> = ({
  id,
  item,
  preCheckoutInformation,
  fetchPreCheckoutData,
}: DetailsPageProps) => {
  const [selectedVersion, setSelectedVersion] = useState<Version | undefined>(item?.versions?.[0] ?? undefined);

  useEffect(() => {
    if (item?.versions) {
      const txId = item.versions.length > 0 && item.versions[0].name ? item.versions[0].id : item?.itemId;
      setSelectedVersion({
        ...item!.versions![0],
        id: txId,
      });
    }
  }, [item]);

  /* istanbul ignore next */
  const handleChange = (newSelectedSku: any) => {
    setSelectedVersion(newSelectedSku);
  };

  if (!item) return <div>No item data</div>;

  if (!item?.versions) return <div>No default version</div>;

  const getFirstVersionDetails = () => {
    const defaultVersion = selectedVersion;
    return {
      status: defaultVersion?.status || ItemStatus.unavailable,
      amount: defaultVersion?.price || 0,
      currency: defaultVersion?.currency || "",
    };
  };

  const firstVersionDetails = getFirstVersionDetails();

  const renderModal = ({ close }: { close: () => void }) => {
    return (
      <SubmitOrderModal
        close={close}
        preCheckoutData={preCheckoutInformation}
        fetchPreCheckoutData={fetchPreCheckoutData!}
        taxonomyId={selectedVersion!.id!}
        name={item.name!}
        status={item.status}
        etaDays={item.etaDays}
        hasStock={item.hasStock}
        //version={selectedSku!}
        orderWorkflow={item.orderWorkflow!}
      ></SubmitOrderModal>
    );
  };

  return (
    <WithModal renderModal={renderModal}>
      {({ open }: { open: () => void }) => (
        <>
          <Container className="product-details" paddingBottom="S500" paddingTop="S500" paddingHorizontal="S300">
            <View>
              <Row key={id} gridGap="S700" alignItems="flex-start" className="product-description-box">
                <Col className="column-left">
                  <Row gridGap="S100">
                    <img
                      data-details-logo="details-logo"
                      className="product-img"
                      src={item.logoImageUrl}
                      alt={`${item.name} logo`}
                    ></img>
                  </Row>
                </Col>
                <Col className="column-center">
                  <Text fontSize="T200" className="product-title" data-cy="product-title">
                    {item.name}
                  </Text>

                  {item?.shortDescription && (
                    <div className="product-short-details" data-cy="product-short-details">
                      <Text
                        data-details-description="details-description"
                        fontSize="T100"
                        className="product-description"
                      >
                        {item.shortDescription}
                      </Text>
                    </div>
                  )}

                  <hr />

                  <Row columnGap={60} gridGap={0}>
                    <Text className="product-property">
                      <label data-cy="os-title">{getString("details.os")}</label>
                      <br />
                      <strong>{item.os?.join(", ")}</strong>
                    </Text>

                    <Text className="product-property">
                      <label data-cy="publisher-title">{getString("details.publisher")}</label>
                      <br />
                      <strong>{item.manufacturer}</strong>
                    </Text>

                    <Text className="product-property">
                      <label data-cy="category-title">{getString("details.category")}</label>
                      <br />
                      <strong>{item.tags?.join(", ")}</strong>
                    </Text>
                  </Row>
                  {item!.versions!.length > 0 && item!.versions![0].name && (
                    <>
                      <Spacer height="S500" />
                      <Col style={{ width: "100%" }}>
                        <SelectChildSku
                          versionsChildSkus={item!.versions!}
                          onChange={handleChange}
                          selectedSku={selectedVersion}
                        />
                      </Col>
                    </>
                  )}
                </Col>
                <Col className="column-right">
                  <OrderNow
                    //version={{ ...selectedSku!, price: totalPrice }}
                    //references={item.references}
                    orderNowHandler={open}
                    etaStatusIndicatorProps={{ hasStock: item.hasStock, etaDays: item.etaDays }}
                    orderRedirectUrl={item.references?.orderRedirectURL || ""}
                    moreDetailsUrl={item.references?.statusMoreDetailsURL || ""}
                    {...firstVersionDetails}
                  />
                </Col>
              </Row>
            </View>
          </Container>

          <Container className="about-product-view" paddingBottom="S500" paddingTop="S500" paddingHorizontal="S300">
            <View>
              <Row
                key={id}
                gridGap="S700"
                alignItems="center"
                className="product-description-box"
                data-cy="product-description-box"
              >
                <Col className="column-left"></Col>
                <Col className="column-center">
                  {item.description && (
                    <View>
                      <div>{parse(item.description)}</div>
                      <Hr color="neutral30" />
                      <CustomerFeedback
                        moduleName="productDetails"
                        alignment={CustomerFeedbackAlignment.Left}
                        position={CustomerFeedbackIconPosition.Left}
                      ></CustomerFeedback>
                    </View>
                  )}
                </Col>
                <Col className="column-right"></Col>
              </Row>
            </View>
          </Container>
        </>
      )}
    </WithModal>
  );
};
