import { CATALOGICAL_ENDPOINT } from "config";
import { StatusCodes } from "http-status-codes";

import {
  CatalogicalGetAllItemsResponse,
  CatalogicalGetItemResponse,
  CatalogicalRequestContent,
} from "../../common/types/catalogical-type";
import { decodeHtmlEntityDeep } from "../helpers/htmlEntityDecode-helper";
import { BaseService } from "./base-service";

export class CatalogicalService extends BaseService {
  constructor() {
    super(CATALOGICAL_ENDPOINT);
  }

  public async getCatalogItemByTaxonomyId(id: string): Promise<CatalogicalGetItemResponse> {
    // The regular expression is used to remove the 'atn::software/' prefix from the ID if present, the API expects the ID this way.
    const formattedTaxonomyID = id.replace(/^atn::software\//, "");
    const url = `/item/software/${formattedTaxonomyID}`;
    const response = await this.fetch(url);

    if (response.status === StatusCodes.OK) {
      const itemResponse = await response.json();
      // Decode HTML entities in the entire response object
      return decodeHtmlEntityDeep(itemResponse);
    }
    throw new Error("Failed to fetch catalog item with Taxonomy ID: " + id);
  }

  public async getCatalogicalItems(parameters: CatalogicalRequestContent): Promise<CatalogicalGetAllItemsResponse> {
    const queryParams = new URLSearchParams({
      limit: `${parameters.limit}`,
      offset: `${parameters.offset}`,
      sortBy: `${parameters.sortBy}`,
      sortDirection: `${parameters.sortDirection}`,
      filter: `${parameters.filter}`,
    });

    if (parameters.fuzzy != undefined && parameters.keywords != undefined) {
      queryParams.append("fuzzy", `${parameters.fuzzy}`);
      queryParams.append("keywords", `${parameters.keywords}`);
    }

    const url = `/items/software/?${queryParams.toString()}`;
    const response = await this.fetch(url);
    if ([StatusCodes.OK, StatusCodes.CREATED].includes(response.status)) {
      const itemsResponse = await response.json();
      // Decode HTML entities in the entire response object
      return decodeHtmlEntityDeep(itemsResponse);
    }

    throw new Error("Failed to fetch catalog items");
  }
}
