import { FacetItem, Facets } from "../types/search-types";

export class FacetsHelper {
  /**
   * Method that returns the facet items array based on the given key
   * @param facets Facet dictionary
   * @param key Key to retrieve
   * @returns An array of key and value (Facet item)
   */
  public static getValuesFromFacets(facets: Facets | undefined, key: string): [string, FacetItem][] {
    if (facets && facets[key]) {
      return Object.entries(facets[key]);
    }

    return [];
  }

  /**
   * Excludes a facet item from the given list
   * @param facetsList The original facets list
   * @param facetToExclude The name of the facet to exclude
   * @returns A new list with the element excluded. If the element is not found, it'll return the same list
   */
  public static excludeFacetsFromList(
    facetsList: [string, FacetItem][],
    facetToExclude: string
  ): [string, FacetItem][] {
    const index = facetsList.findIndex((facet: [string, FacetItem]) => facet[0] === facetToExclude);
    if (index !== -1) {
      return [...facetsList.slice(0, index), ...facetsList.slice(index + 1)];
    }
    return facetsList;
  }
}
