import {
  Checkbox,
  FormWrapper,
  Input,
  InputFooter,
  InputWrapper,
  LabelPosition,
  Select,
  TextArea,
} from "@amzn/stencil-react-components/form";
import { Col } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Label, Text } from "@amzn/stencil-react-components/text";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { useEffect, useState } from "react";

import questionsData from "./formQuestions.json";

export interface SecurityDataFormProps {
  onSave: (formData: string) => void;
  onValidationSubmit: (enableSubmit: boolean) => void;
  softwareName: string;
}

export const SecurityDataForm: React.FC<SecurityDataFormProps> = ({ onSave, onValidationSubmit, softwareName }) => {
  const [formData, setFormData] = useState<any>({});

  const replacePlaceholders = (text: string, variables: { [key: string]: string }) => {
    return text.replace(/{{(.*?)}}/g, (_, key) => variables[key] || "");
  };

  const handleInputChange = (field: string, value: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [field]: value,
    }));
  };

  useEffect(() => {
    const formattedData = questionsData.questions
      .map((question) => {
        const fieldValue = Array.isArray(formData[question.id])
          ? "Options selected: " + formData[question.id].join(", ")
          : formData[question.id] || "";

        // Assign “N/A” if the question is optional and has no value.
        const displayValue = fieldValue || (!question.isRequired ? "No options selected" : "");

        return `<strong>${replacePlaceholders(question.label, { softwareName })}</strong> ${displayValue}<br/>`;
      })
      .join("");

    onSave(formattedData);

    // Check if all required fields are filled in
    const allRequiredFieldsFilled = questionsData.questions
      .filter((question) => question.isRequired)
      .every((question) => {
        const fieldData = formData[question.id];
        if (Array.isArray(fieldData)) {
          return fieldData.length > 0;
        }
        return fieldData && fieldData.trim() !== "";
      });

    onValidationSubmit(allRequiredFieldsFilled);
  }, [formData, onSave, onValidationSubmit]);

  return (
    <FormWrapper id="security-form" data-testid="security-form">
      {questionsData.questions.map((question) => (
        <Col maxWidth={815} key={question.id}>
          <Label htmlFor={question.id}>
            {replacePlaceholders(question.label, { softwareName })}
            {question.isRequired && <span> *</span>}
            {!question.isRequired && <span style={{ color: "gray" }}> (Optional)</span>}
          </Label>
          {question.inputType === "checkbox" ? (
            <>
              {question.options?.map((option: string, index: number) => (
                <InputWrapper
                  key={index}
                  id={`${question.id}-${index}`}
                  labelText={option}
                  labelPosition={LabelPosition.Trailing}
                  data-testid={`input-${question.id}`}
                >
                  {(inputProps) => (
                    <Checkbox
                      name={`${question.id}-${index}`}
                      data-testid={`input-${question.id}-${index}`}
                      checked={formData[question.id]?.includes(option) || false}
                      onChange={(e) => {
                        const checkedOptions = formData[question.id] || [];
                        if (e.target.checked) {
                          handleInputChange(question.id, [...checkedOptions, option]);
                        } else {
                          handleInputChange(
                            question.id,
                            checkedOptions.filter((o: string) => o !== option)
                          );
                        }
                      }}
                      {...inputProps}
                    />
                  )}
                </InputWrapper>
              ))}
            </>
          ) : question.inputType === "textarea" ? (
            <>
              <TextArea
                id={question.id}
                data-testid={`input-${question.id}`}
                placeholder={question.placeholder}
                value={formData[question.id] || ""}
                onChange={(e) => handleInputChange(question.id, e.target.value)}
              />
            </>
          ) : question.inputType === "select" ? (
            <>
              <Select
                id={question.id}
                data-testid={`input-${question.id}`}
                placeholder={question.placeholder}
                value={formData[question.id] || ""}
                options={question.options!}
                onChange={(e) => handleInputChange(question.id, e)}
              />
            </>
          ) : (
            <>
              <Input
                id={question.id}
                data-testid={`input-${question.id}`}
                type="text"
                placeholder={question.placeholder}
                value={formData[question.id] || ""}
                onChange={(e) => handleInputChange(question.id, e.target.value)}
              />
            </>
          )}

          {question.inputFooter && (
            <InputFooter id={`${question.id}-input-footer`}>
              {question.inputFooter.part1}
              <Link href={question.inputFooter.url1} target="_blank">
                {question.inputFooter.part2}
              </Link>
              {question.inputFooter.part3}
            </InputFooter>
          )}
          <br />
        </Col>
      ))}
      <Col gridGap="S200" alignSelf="flex-start">
        <Text fontSize="T100" color="neutral70">
          {getString("submitSanctionOrder.requiredField")}
        </Text>
      </Col>
    </FormWrapper>
  );
};
