import "./searchResults.scss";

import { Card } from "@amzn/stencil-react-components/card";
import { useResizeObserver } from "@amzn/stencil-react-components/hooks";
import { Col, Hr, Row, View } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { Text } from "@amzn/stencil-react-components/text";
import { CustomerFeedback, CustomerFeedbackAlignment, CustomerFeedbackIconPosition, TopBar } from "common/components";
import { NoResultsFound } from "common/components/NoResultsFound";
import { FacetsHelper } from "common/helpers/facets-helper";
import { getString } from "common/uistringlabels/uiStringUtils";
import CatalogItemsList from "modules/components/CatalogItemsList";
import { useSearchCatalogItems } from "modules/components/CatalogItemsList/hook";
import FacetedFilter from "modules/components/FacetedFilter";
import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const CATALOG_ITEMS_LIST_CONTAINER_ID = "search-results-column";

const SearchResults: FC = () => {
  const [cfmStyle, setCfmStyle] = useState("cfm-scroll");

  const location = useLocation();
  const { ref } = useResizeObserver<HTMLDivElement>();

  let queryValue: string | null = "";

  if (location?.search) {
    queryValue = new URLSearchParams(location.search).get("query");
  }

  const {
    isLoading,
    isLoadingNextItems,
    searchCatalogItems,
    searchNext,
    totalCount,
    facets,
    searchParameters,
    nextToken,
  } = useSearchCatalogItems();

  useEffect(() => {
    if (queryValue) {
      searchCatalogItems({ text: queryValue, pageSize: 20 }, true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  const onLoadMore = () => {
    if (nextToken && !isLoadingNextItems && !isLoading) {
      searchNext({ ...searchParameters, pageSize: 20, nextToken: nextToken, includeFacets: false });
    }
  };

  /* istanbul ignore next */
  const shouldLoadMore = () => {
    if (!ref.current) {
      return false;
    }
    // if not overflowing
    // !visible height < total height
    return !hasVisibleScroll();
  };

  let categoryFacets = FacetsHelper.getValuesFromFacets(facets, "categories");
  categoryFacets = FacetsHelper.excludeFacetsFromList(categoryFacets, "Other");

  /* istanbul ignore next */ // TODO research how to mock user interactions (To be resolved by ITMP-345)
  const setCategoriesFilter = (filterValue: string) => {
    return { ...searchParameters, categories: [filterValue], nextToken: undefined };
  };

  /* istanbul ignore next */
  const clearCategoriesFilter = () => {
    return { ...searchParameters, categories: [], nextToken: undefined };
  };

  /* istanbul ignore next */
  const setTagsFilter = (filterValue: string) => {
    return { ...searchParameters, tags: [filterValue], nextToken: undefined };
  };

  /* istanbul ignore next */
  const clearTagsFilter = () => {
    return { ...searchParameters, tags: [], nextToken: undefined };
  };

  /* istanbul ignore next */
  const hasVisibleScroll = () => {
    if (ref?.current) {
      return ref.current.clientHeight < ref.current.scrollHeight;
    }

    return false;
  };

  useLayoutEffect(() => {
    hasVisibleScroll() ? setCfmStyle("cfm-scroll") : setCfmStyle("cfm-no-scroll");
  }, [isLoading, hasVisibleScroll(), ref]);

  if (isLoading) {
    return (
      <View padding="S700">
        <Spinner size={SpinnerSize.Medium} fr={undefined} />
      </View>
    );
  }

  if (totalCount == 0) {
    return (
      <View className="search-results-container">
        <TopBar>
          <Text fontSize="T300" color="neutral0">
            {getString("noResults.searchResultText", { text: queryValue })}
          </Text>
        </TopBar>
        <NoResultsFound searchTerm={queryValue} />
      </View>
    );
  }

  return (
    <View className="search-results-container">
      <Row>
        <TopBar>
          <Text fontSize="T300" color="neutral0">
            {getString("catalogItemsList.resultsMessage", { totalCount: totalCount, text: queryValue })}
          </Text>
        </TopBar>
      </Row>

      <Row width="100%" height="100%">
        <Col width={297} height="100%" backgroundColor="neutral05">
          <Col width={297} className="search-results-column" height="calc(100% - 110px)">
            <FacetedFilter
              facets={FacetsHelper.getValuesFromFacets(facets, "tags")}
              title={getString("categoryFilter.categoryTitle")}
              allOptionText={getString("categoryFilter.allCategories")}
              setSearchParameters={setTagsFilter}
              clearSearchParameters={clearTagsFilter}
            />
            <FacetedFilter
              facets={categoryFacets}
              title={getString("categoryFilter.osTitle")}
              allOptionText={getString("categoryFilter.allOperatingSystems")}
              setSearchParameters={setCategoriesFilter}
              clearSearchParameters={clearCategoriesFilter}
            />
          </Col>

          <Col width={297} padding={[0, 0, "S400", 0]} height="110px" justifyContent="flex-end">
            <Col width="70%" margin={["S500", 0, 0, "S500"]}>
              <Hr size="wide" color="neutral30" />
            </Col>

            <Text color="neutral70" margin={["S300", 0, 0, "S500"]} fontSize="T14">
              {getString("submitSoftwareRequest.header")}
            </Text>
            <Link
              textDecoration="none"
              fontWeight="bold"
              href={getString("externalLinks.uniqueRequests")}
              color="primary80"
              margin={["S300", 0, 0, 0]}
              padding={[0, 0, 0, "S500"]}
              fontSize="T14"
              data-cy="submit-request-link"
            >
              {getString("submitSoftwareRequest.link")}
            </Link>
          </Col>
        </Col>

        <Col className="search-results-column" id={CATALOG_ITEMS_LIST_CONTAINER_ID} ref={ref}>
          <CatalogItemsList
            onSearchNext={onLoadMore}
            scrollableTarget={CATALOG_ITEMS_LIST_CONTAINER_ID}
            shouldLoadMore={shouldLoadMore}
          />
        </Col>
      </Row>
      <Card
        className={`cfm-general ${cfmStyle}`}
        data-testid="cfm-card"
        isElevated={true}
        border={"1px solid neutral50"}
        style={{ position: "absolute", top: "55%" }}
      >
        <CustomerFeedback
          moduleName="searchResults"
          alignment={CustomerFeedbackAlignment.Centered}
          position={CustomerFeedbackIconPosition.Bottom}
        />
      </Card>
    </View>
  );
};

export default SearchResults;
