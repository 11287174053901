import "./style.scss";

import { InputWrapper } from "@amzn/stencil-react-components/form";
import { Container } from "@amzn/stencil-react-components/layout";
import { SearchField, useSearch } from "@amzn/stencil-react-components/search";
import { TrafficFrom } from "common/helpers/traffic-from";
import { CatalogicalItem } from "common/types/catalogical-type";
import { getString } from "common/uistringlabels/uiStringUtils";
import { debounce } from "lib/helpers/debounce";
import { CatalogicalService } from "lib/services/catalogical-service";
import { ItemTaxonomyId } from "lib/taxonomy/item-taxonomy-id";
import React, { FunctionComponent, useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { AutocompleteSearchResult } from "../AutocompleteSearchResult";

export interface CatalogicalSearchBoxProps {
  /**
   * The width of the Search Box
   */
  width?: string;
  /**
   * The query string
   */
  queryText?: string | null;
}

export interface SearchBoxItem {
  name: string;
  taxonomyId: string;
}

export const CatalogicalSearchBox: FunctionComponent<CatalogicalSearchBoxProps> = ({ width, queryText }) => {
  let placeholderText = getString("searchBox.placeholder");

  /* istanbul ignore next */
  if (!queryText) queryText = "";

  /* istanbul ignore next */
  if (location.pathname == "/") {
    placeholderText = getString("searchBox.placeholder_home");
  }

  const [query, setQuery] = useState(queryText);
  const [totalItemsCount, setTotalItemsCount] = useState<number | undefined>(undefined);

  const catalogicalService = new CatalogicalService();

  /* istanbul ignore next */
  const getSearchResultsFromAPI = async (searchText: string): Promise<CatalogicalItem[]> => {
    setQuery(searchText);
    if (!(searchText?.length > 2)) {
      return [];
    }
    try {
      const results = await catalogicalService.getCatalogicalItems({
        keywords: searchText,
        offset: 0,
        limit: 5,
        fuzzy: true,
        sortBy: "name",
        sortDirection: "asc",
        filter: "",
      });
      setTotalItemsCount(results.pagination.total);
      return results.data;
    } catch (error) {
      return [];
    }
  };

  const getSearchResultsDebounced = useCallback(debounce(getSearchResultsFromAPI, 1000)[0], []);

  const { searchFieldProps } = useSearch({
    onQueryChange: getSearchResultsDebounced,
  });

  /* istanbul ignore next */
  const resultCountTextHandler = () => {
    return totalItemsCount == null ? getString("searchBox.noResults") : `${totalItemsCount} results`;
  };

  const history = useHistory();

  // How to display the result once its selected
  const resultToStringHandler = (result: SearchBoxItem) => result.taxonomyId;

  // When an item is selected from the autocomplete
  /* istanbul ignore next */
  const onResultClickHandler = (option: SearchBoxItem): void => {
    const itemTaxId = ItemTaxonomyId.Parse(option.taxonomyId);
    history.push(`/items/${itemTaxId.getUrlSuffix()}?from=${TrafficFrom.Autocomplete}`);
  };

  // When the view all results is selected
  /* istanbul ignore next */
  const onListActionButtonClickHandler = () => {
    history.push(`/search?query=${query}`);
  };

  // textInput must be declared here so the ref can refer to it
  const textInput = useRef<HTMLInputElement>(null);

  /* istanbul ignore next */
  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    history.push(`/search?query=${textInput.current?.value}`);
    textInput.current?.blur();
    return false;
  };

  const renderResultAutoComplete = (result: CatalogicalItem) => {
    return (
      <AutocompleteSearchResult
        logo_url={result.logo_url}
        categories={result.categories}
        taxonomyId={result.taxonomyId}
        name={result.name}
      />
    );
  };

  return (
    <Container isFullWidth={true}>
      <form onSubmit={onSubmitHandler} target="/search">
        <InputWrapper id="search-box" labelText="">
          {(inputProps: any) => (
            <SearchField
              ref={textInput}
              width={width}
              data-cy="search-input"
              placeholder={placeholderText}
              renderResult={renderResultAutoComplete}
              resultToString={resultToStringHandler}
              resultCountText={resultCountTextHandler}
              onResultClick={onResultClickHandler}
              onListActionButtonClick={onListActionButtonClickHandler}
              shouldAutocomplete={false}
              hasSubmitButton={true}
              {...inputProps}
              {...searchFieldProps}
            />
          )}
        </InputWrapper>
      </form>
    </Container>
  );
};
