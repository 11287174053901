import { Header } from "common/components/Header";
import { MessageBannersBox } from "common/components/MessageBannersBox";
import { CATALOG_PROVIDER } from "config";
import React, { FC } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ApprovalPage from "view/Page/ApprovalPage";
import CatalogicalDetailsPage from "view/Page/CatalogicalDetailsPage";
import CatalogicalSearchResults from "view/Page/CatalogicalSearchResults";
import DetailsPage from "view/Page/DetailsPage";
import HomePage from "view/Page/Home";
import NotFoundPage from "view/Page/NotFound";
import OrderConfirmation from "view/Page/OrderConfirmation";
import { OrdersApprovalPage } from "view/Page/OrdersApprovalPage";
import { OrdersPage } from "view/Page/OrdersPage";
import SearchResults from "view/Page/SearchResults";

// Keeping this commented imports for the easier switch to itmpV1
// import SearchResults from "view/Page/SearchResults";
// import DetailsPage from "view/Page/DetailsPage";

const Routes: FC = () => (
  <BrowserRouter>
    <Header />
    <MessageBannersBox />
    <Switch>
      <Route
        component={CATALOG_PROVIDER === "catalogical" ? CatalogicalSearchResults : SearchResults}
        exact
        path="/search"
        sensitive
      />
      <Route component={OrderConfirmation} path="/confirmation" sensitive />
      <Route
        component={CATALOG_PROVIDER === "catalogical" ? CatalogicalDetailsPage : DetailsPage}
        exact
        path="/items/*"
        sensitive
      />
      <Route component={ApprovalPage} exact path="/order/:orderId/:newStatus" sensitive />
      <Route component={OrdersPage} exact path="/orders" sensitive />
      <Route component={OrdersApprovalPage} exact path="/ordersApproval" sensitive />
      <Route component={HomePage} exact path="/*" sensitive />
      <Route component={NotFoundPage} path="/*" />
    </Switch>
  </BrowserRouter>
);

export default Routes;
