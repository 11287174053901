import "./style.scss";

import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Checkbox, TextArea } from "@amzn/stencil-react-components/form";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { MessageBanner } from "@amzn/stencil-react-components/message-banner";
import { ModalContent } from "@amzn/stencil-react-components/modal";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { Label, Text } from "@amzn/stencil-react-components/text";
import { InlineUserReference } from "common/components/InlineUserReference";
import { CreateOrderRequestContent } from "common/types";
import { PreCheckoutInformation } from "common/types";
import { MessageStruct } from "common/types/ui-types";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FunctionComponent, useState } from "react";

export interface SubmitSanctionedOrderModalProps {
  name: string;
  taxonomyId: string;
  submitOrderHandler: (request: CreateOrderRequestContent) => void;
  preCheckoutInformation?: PreCheckoutInformation;
  close: () => void;
  csrfTokenIsLoading: boolean;
  isLoadingCreate: boolean;
  messages?: MessageStruct[];
}

/**
 * Reusable component to submit a sanctioned order
 */

export const SubmitSanctionedOrderModal: FunctionComponent<SubmitSanctionedOrderModalProps> = ({
  name,
  taxonomyId,
  preCheckoutInformation,
  submitOrderHandler,
  close,
  csrfTokenIsLoading,
  isLoadingCreate,
  messages,
}: SubmitSanctionedOrderModalProps) => {
  const [confirmIsChecked, setConfirmIsChecked] = useState(false);
  const [comment, setComment] = useState<undefined | string>(undefined);

  const submitOrder = async () => {
    const request: CreateOrderRequestContent = {
      items: {
        [taxonomyId!]: { quantity: 1 },
      },
      comment,
    };
    await submitOrderHandler(request);
  };

  /* istanbul ignore next */
  const canClickSubmmit = () => {
    if (!confirmIsChecked || isLoadingCreate) return false;
    if (preCheckoutInformation !== undefined && preCheckoutInformation!.canBeAutoApproved) return true;
    return comment != undefined && comment.trim();
  };

  /* istanbul ignore next */
  const selectModalBodyComponent = () => {
    if (!preCheckoutInformation || csrfTokenIsLoading) {
      return <Spinner size={SpinnerSize.Medium} fr={undefined} />;
    }

    if (preCheckoutInformation.canBeAutoApproved) {
      return (
        <Text color="neutral90" fontSize="T300">
          {getString("submitSanctionOrder.autoApproveOrderBody")}
        </Text>
      );
    }

    const requiredApprover = preCheckoutInformation.requiredApprover!;

    return (
      <Col gridGap="S100">
        <Text color="neutral90" fontSize="T200">
          {getString("submitSanctionOrder.managerRequest.body")}
          <strong>
            {" "}
            <InlineUserReference userReference={requiredApprover}></InlineUserReference>{" "}
          </strong>
        </Text>
        <Label htmlFor="comment-text-area">{getString("submitSanctionOrder.managerRequest.textAreaLabel")}</Label>
        <TextArea
          id="comment-text-area"
          data-cy="comment-text-area"
          placeholder={getString("submitSanctionOrder.managerRequest.textAreaPlaceHolder")}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Col>
    );
  };

  const modalBodyComponent = selectModalBodyComponent();

  let messagesDisplay = <Text></Text>;
  if (messages && messages.length > 0) {
    const listItems = messages.map((d) => (
      <MessageBanner key={d.message} type={d.type}>
        {d.message}
      </MessageBanner>
    ));
    messagesDisplay = (
      <div>
        <hr />
        {listItems}
      </div>
    );
  }

  return (
    <ModalContent
      titleText={name ? name : ""}
      buttons={[
        <Button key="submitOrder-close" variant={ButtonVariant.Tertiary} onClick={close}>
          Cancel
        </Button>,
        <Button
          key="submitOrder-submit"
          data-testid="submitOrder-submit"
          data-cy="submit-order-button"
          variant={ButtonVariant.Primary}
          disabled={!canClickSubmmit()}
          onClick={submitOrder}
        >
          Submit
          {isLoadingCreate ? <Spinner size={SpinnerSize.Small} fr={undefined} /> : null}
        </Button>,
      ]}
    >
      <Col gridGap="S400" alignItems="center">
        <Col gridGap="S200">{modalBodyComponent}</Col>
        <Col gridGap="S200">
          <Row gridGap="S200">
            <Checkbox
              id="checkReadAcceptableUsePolicy"
              data-cy="read-acceptable-use-policy-checkbox"
              checked={confirmIsChecked}
              onChange={(e: any) => setConfirmIsChecked(e.target.checked)}
            />
            <Label htmlFor="checkReadAcceptableUsePolicy">
              {getString("submitSanctionOrder.acceptConditions.part1")}
              <Link href={"https://policy.a2z.com/docs/111/publication"} target="_blank">
                {getString("submitSanctionOrder.acceptConditions.part2")}
              </Link>
              {getString("submitSanctionOrder.acceptConditions.part3")}
            </Label>
          </Row>
        </Col>
        <Col gridGap="S200" alignSelf="flex-start">
          <Text fontSize="T100" color="neutral70">
            {getString("submitSanctionOrder.requiredField")}
          </Text>
        </Col>
      </Col>
      {messagesDisplay}
    </ModalContent>
  );
};
