import "./style.scss";

import { Col } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Text } from "@amzn/stencil-react-components/text";
import { FacetItem, SearchRequestContent } from "common/types/search-types";
import React, { FC, useState } from "react";

import { useSearchCatalogItems } from "../CatalogItemsList/hook";

export interface FacetedFilterProps {
  /**
   * The categories list from facets to display
   */
  facets: [string, FacetItem][] | undefined;

  /**
   * Title of the filter component
   */
  title: string;

  /**
   * All option text
   */
  allOptionText: string;

  /**
   * Function to set the search parameters for the given facet
   */
  setSearchParameters: (filterValue: string) => SearchRequestContent;

  /**
   * Function to clear the search parameters
   */
  clearSearchParameters: () => SearchRequestContent;
}

const FacetedFilter: FC<FacetedFilterProps> = ({
  facets,
  title,
  allOptionText,
  setSearchParameters,
  clearSearchParameters,
}) => {
  const { searchCatalogItems } = useSearchCatalogItems();

  // istanbul ignore next
  const onAllOptionsClick = () => {
    setSelected([""]);
    searchCatalogItems(clearSearchParameters(), false);
  };

  // istanbul ignore next
  const onFacetFilterClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const filterValue = event.currentTarget.dataset.filtervalue;
    if (filterValue) {
      setSelected([filterValue]);
      searchCatalogItems(setSearchParameters(filterValue), false);
    }
  };

  const [selected, setSelected] = useState([""]);

  return (
    <Col padding={[0, 0, "S700", 0]}>
      <Text color="neutral70" margin={["S300", 0, 0, "S500"]} data-cy="facet-filter-title">
        {title}
      </Text>
      <Link
        textDecoration="none"
        fontWeight={selected.includes("") ? "bold" : "regular"}
        href="#"
        color="neutral90"
        margin={["S300", 0, 0, 0]}
        padding={[0, 0, 0, "S500"]}
        onClick={onAllOptionsClick}
      >
        {allOptionText}
      </Link>
      {facets?.map((item) => (
        <Link
          textDecoration="none"
          data-filtervalue={item[0]}
          key={item[0]}
          onClick={onFacetFilterClick}
          color="neutral90"
          href="#"
          margin={["S300", 0, 0, 0]}
          padding={[0, 0, 0, "S500"]}
          fontWeight={selected.includes(item[0]) ? "bold" : "regular"}
        >
          {item[1].label}
        </Link>
      ))}
    </Col>
  );
};

export default FacetedFilter;
