import "./orderConfirmation.scss";

import { IconCheckCircleFill, IconChevronLeft, IconSize } from "@amzn/stencil-react-components/icons";
import { Col, Container, Row, View } from "@amzn/stencil-react-components/layout";
import { Link, LinkIconPosition } from "@amzn/stencil-react-components/link";
import { Text } from "@amzn/stencil-react-components/text";
import { CustomerFeedback, CustomerFeedbackAlignment, CustomerFeedbackIconPosition, TopBar } from "common/components";
import CatalogicalRecommendedItems from "common/components/CatalogicalRecommendedItems";
import { InlineUserReference } from "common/components/InlineUserReference";
import RecommendedItemsFactory from "common/factories/recommended-items-factory";
import { CreateOrderResponseContent } from "common/types";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";

export interface OrderConfirmationState extends CreateOrderResponseContent {
  canBeAutoApproved?: boolean;
}

const OrderConfirmation: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const localState: OrderConfirmationState | undefined = location.state as CreateOrderResponseContent;
  if (localState == undefined) {
    history.push("/");
  }

  const obtainInstructionsComponent = (localState: OrderConfirmationState | undefined) => {
    if (!localState) return;

    if (localState.canBeAutoApproved) {
      return (
        <Text fontSize="T200" fontWeight="light" margin={["S300", 0, 0, 0]}>
          {getString("orderConfirmation.autoApprovedOrder.instructions")}
        </Text>
      );
    }

    const approverReferenceComponent = (
      <InlineUserReference userReference={localState.requiredApprover!}></InlineUserReference>
    );

    return (
      <Text fontSize="T200" fontWeight="light" margin={["S300", 0, 0, 0]}>
        {`${getString("orderConfirmation.managerRequest.instructions.part1")} `} {approverReferenceComponent}{" "}
        {`${getString("orderConfirmation.managerRequest.instructions.part2")}`}
      </Text>
    );
  };

  /* istanbul ignore next */
  const onBackToHomeClick = (e: any) => {
    e.preventDefault();
    history.push("/");
  };

  return (
    <>
      <TopBar isFullWidth={false}>
        <Link
          href="#"
          onClick={onBackToHomeClick}
          color="primary40"
          icon={<IconChevronLeft color="neutral40" title={getString("orderConfirmation.backToHome")} />}
          iconPosition={LinkIconPosition.Leading}
          textDecoration="none"
        >
          {getString("orderConfirmation.backToHome")}
        </Link>
      </TopBar>
      <Container>
        <View>
          <Row margin={["S700", 0, "128px", 0]} gridGap="S300">
            <Col padding={["6px", 0, 0, 0]}>
              {/* Ideally here will check for the order status and display the corresponding icon */}
              <IconCheckCircleFill
                color="green70"
                size={IconSize.Medium}
                title={getString("orderConfirmation.success")}
              ></IconCheckCircleFill>
            </Col>
            <Col width="52%">
              <Text fontSize="T400" fontWeight="regular">
                {/* Ideally here will check for the order status and display the corresponding message */}
                {getString("orderConfirmation.success")}
              </Text>
              {obtainInstructionsComponent(localState)}
              <CustomerFeedback
                moduleName="orderFlow"
                alignment={CustomerFeedbackAlignment.Left}
                position={CustomerFeedbackIconPosition.Left}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>
                <Text fontSize={"T400"} fontWeight={500} color="neutral70">
                  {getString("home.recommended")}
                </Text>
              </h2>
              <RecommendedItemsFactory pageSize={4} categoryToFilterBy="essential" />
            </Col>
          </Row>
        </View>
      </Container>
    </>
  );
};

export default OrderConfirmation;
