import { useMessageBannersBox } from "common/components/MessageBannersBox/hook";
import { sendSiteSearchMetric } from "common/helpers/analytics-helper";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { CatalogItemSimplified, SearchRequestContent } from "common/types";
import { Facets, SearchResponseContent } from "common/types/search-types";
import { getString } from "common/uistringlabels/uiStringUtils";
import { useState } from "react";

import {
  fetchCatalogItems,
  selectFacets,
  selectNextToken,
  selectResultItems,
  selectSearchParameters,
  selectTotalResultCount,
  updateFacetsAction,
  updateNextTokenAction,
  updateSearchParametersAction,
} from "./reducer";

export interface UseSearchCatalogItemsHookResult {
  /**
   * Loading state
   */
  isLoading: boolean;

  /**
   * Function to execute the search
   */
  searchCatalogItems: (query: SearchRequestContent, willUpdateFacets: boolean, shouldSendMetric?: boolean) => void;

  /**
   *
   */
  searchNext: (query: SearchRequestContent) => void;

  /**
   * Total result count
   */
  totalCount: number | undefined;

  /**
   * Search parameters
   */
  searchParameters: SearchRequestContent | undefined;

  /**
   * Search Result Items
   */
  items: CatalogItemSimplified[] | undefined;

  /**
   * Facets of the search
   */
  facets: Facets | undefined;

  /**
   * Next token
   */
  nextToken: string | undefined;

  /** */
  isLoadingNextItems: boolean;
}

/**
 * Generic search items hook
 * @param searchCatalogItemsProps contains the necessary functions to execute the search.
 * @returns a function that executes the search
 */
export function useSearchCatalogItems(): UseSearchCatalogItemsHookResult {
  const dispatch = useAppDispatch();
  const totalCount = useAppSelector(selectTotalResultCount);
  const items = useAppSelector(selectResultItems);
  const facets = useAppSelector(selectFacets);
  const searchParameters = useAppSelector(selectSearchParameters);
  const nextToken = useAppSelector(selectNextToken);
  const { addErrorBanner } = useMessageBannersBox();

  const [isLoading, setLoading] = useState(true);
  const [isLoadingNextItems, setLoadingNextItems] = useState(false);

  const searchCatalogItems = (
    query: SearchRequestContent,
    willUpdateFacets: boolean,
    shouldSendMetric: boolean = false
  ): void => {
    setLoading(true);
    dispatch(updateSearchParametersAction(query));
    dispatch(fetchCatalogItems(query))
      .then((result) => {
        if (result.payload) {
          const payload = result.payload as SearchResponseContent;
          const nextToken = payload.nextToken;
          const facets = payload.facets;

          dispatch(updateNextTokenAction(nextToken!));
          if (willUpdateFacets) {
            dispatch(updateFacetsAction(facets));
          }

          if (shouldSendMetric) {
            /**
             * Using the payload to send to get the metric data, this is the most consistent point
             * to avoid duplicated called or use of outdated data
             */
            sendSiteSearchMetric({
              totalResults: payload.totalResults!,
              terms: query!.text!.split(" "),
            });
          }
        } else {
          addErrorBanner(getString("errors.general"));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchNext = async (query: SearchRequestContent) => {
    setLoadingNextItems(true);
    dispatch(updateSearchParametersAction(query));
    dispatch(fetchCatalogItems(query))
      .then((result) => {
        if (result.payload) {
          const nextToken = (result.payload as SearchResponseContent).nextToken;
          dispatch(updateNextTokenAction(nextToken!));
        } else {
          addErrorBanner(getString("errors.general"));
        }
      })
      .finally(() => {
        setLoadingNextItems(false);
      });
  };

  return {
    isLoading,
    isLoadingNextItems,
    searchCatalogItems,
    searchNext,
    searchParameters,
    totalCount,
    items,
    facets,
    nextToken,
  };
}
