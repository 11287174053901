import { GetItemsRequestContent } from "common/types";

export class ItemsHelper {
  /**
   * Create query params based on get items parameters
   * @param parameters Items parameters
   * @returns string with query params
   */
  public static getQueryParams(parameters: GetItemsRequestContent): string {
    const searchParams = new URLSearchParams();

    const { pageSize, nextToken } = parameters;

    if (pageSize) {
      searchParams.append("pageSize", `${pageSize}`);
    }

    if (nextToken) {
      searchParams.append("nextToken", `${nextToken}`);
    }

    return searchParams.toString();
  }
}
