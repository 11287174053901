import "./home-styles.scss";

import { PageContainer } from "@amzn/stencil-react-components/page";
import { Text } from "@amzn/stencil-react-components/text";
import { LearningModule } from "common/components";
import CatalogicalRecommendedItems from "common/components/CatalogicalRecommendedItems";
import {
  CustomerFeedback,
  CustomerFeedbackAlignment,
  CustomerFeedbackIconPosition,
} from "common/components/CustomerFeedback";
import { Hero } from "common/components/Hero";
import RecommendedItemsFactory from "common/factories/recommended-items-factory";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FC } from "react";

const HomePage: FC = () => {
  return (
    <>
      <PageContainer className="hero-container" paddingHorizontal={0} paddingTop={0} isFullWidth={true}>
        <Hero />
      </PageContainer>
      <PageContainer className="recommended-items-container" paddingTop={0} paddingBottom={0} isFullWidth={false}>
        <h2 data-cy="most-popular-title">
          <Text fontSize={"T400"} fontWeight={500} color="neutral70">
            {getString("home.popular")}
          </Text>
        </h2>
        <RecommendedItemsFactory pageSize={4} categoryToFilterBy="popular"></RecommendedItemsFactory>

        <h2 data-cy="amzn-essentials-title">
          <Text fontSize={"T400"} fontWeight={500} color="neutral70">
            {getString("home.recommended")}
          </Text>
        </h2>
        <RecommendedItemsFactory pageSize={4} categoryToFilterBy="essential">
          <CustomerFeedback
            moduleName="mostPopular"
            alignment={CustomerFeedbackAlignment.Right}
            position={CustomerFeedbackIconPosition.Left}
          ></CustomerFeedback>
        </RecommendedItemsFactory>
      </PageContainer>
      <PageContainer
        className="learning-container"
        paddingTop={0}
        paddingBottom={0}
        isFullWidth={false}
        backgroundColor="neutral05"
      >
        <LearningModule />
      </PageContainer>
    </>
  );
};

export default HomePage;
