import "./style.scss";

import { Container, Row, View } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import SearchBoxFactory from "common/factories/search-box-factory";
import React from "react";

import { getString } from "../../uistringlabels/uiStringUtils";

export const Hero = () => (
  <Container className="hero" isFullWidth={false} paddingHorizontal="S500" paddingTop="S700" paddingBottom="S700">
    <View>
      <Row>
        <Text data-cy="hero-title" color="neutral05" fontSize="T600" fontWeight="light">
          {getString("hero.title")}
        </Text>
      </Row>
      <Row margin={["S300", 0, 0, 0]}>
        <SearchBoxFactory width="50%"></SearchBoxFactory>
      </Row>
    </View>
  </Container>
);
