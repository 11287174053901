import { Container, View } from "@amzn/stencil-react-components/layout";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { Text } from "@amzn/stencil-react-components/text";
import { TopBar } from "common/components";
import { useMessageBannersBox } from "common/components/MessageBannersBox/hook";
import { ProductDetailsPage } from "common/components/ProductDetailsPage";
import { TrafficFrom } from "common/helpers/traffic-from";
import { GetItemsResponseContent, PreCheckoutInformation } from "common/types";
import { CatalogItem } from "common/types/catalog-types";
import { getString } from "common/uistringlabels/uiStringUtils";
import { CatalogService } from "lib/services/catalog-service";
import { IOrdersService, OrdersServiceFactory } from "lib/services/orders";
import { ItemTaxonomyId } from "lib/taxonomy/item-taxonomy-id";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import defaultVersionsData from "./defaultVersions.json";

export interface DetailsPageProps {
  id?: string;
  item?: CatalogItem;
  preCheckoutInformation?: PreCheckoutInformation;
  fetchPreCheckoutData?: (token: string, itemTxId: string) => void;
}

const DetailsPage: FC<DetailsPageProps> = (props: DetailsPageProps) => {
  const history = useHistory();
  // Gets the Taxonomy parts from the url location
  const location = useLocation();
  const taxonomyUrl = location.pathname.replace("/items/", "");

  checkIfExternal();

  const atn = ItemTaxonomyId.ParseWithoutTypePrefix(taxonomyUrl);
  const itemTaxonomy = "atn::" + taxonomyUrl;

  const { addErrorBanner } = useMessageBannersBox();

  const [preCheckoutData, setPreCheckoutData] = useState<PreCheckoutInformation | undefined>(undefined);
  const [itemData, setItemData] = useState<CatalogItem | undefined>(props.item);
  const [error, setError] = useState(false);

  // Used to be able to load data async
  useEffect(() => {
    fetchItemData();
  }, [taxonomyUrl]);

  // Fetch the actual data, calling the catalog API
  const fetchItemData = async () => {
    const client = new CatalogService();

    try {
      const items: GetItemsResponseContent = await client.GetItems(atn);
      if (!items.items) {
        throw new Error("No item data found");
      }

      let data = items.items.find((item) => item.itemId === itemTaxonomy);
      if (!data) {
        data = items.items[0];
      }

      // Sort versions alphabetically by shortDescription
      if (data?.versions && data.versions[0].shortDescription) {
        data.versions = _.orderBy(data.versions, [(item) => item.shortDescription?.toLowerCase()], ["asc"]);
      }

      const defaultVersionInfo = defaultVersionsData.defaultVersions.find((entry) => entry.itemId === data?.itemId);

      // Find if there is a default version and move it to the first position
      if (data?.versions && defaultVersionInfo) {
        const defaultVersionId = defaultVersionInfo.defaultVersion.id;
        const defaultVersionIndex = data.versions.findIndex((version) => version.id === defaultVersionId);

        if (defaultVersionIndex > 0) {
          const [defaultVersion] = data.versions.splice(defaultVersionIndex, 1);
          data.versions.unshift(defaultVersion);
        }
      }

      setItemData(data);
      setPreCheckoutData(undefined);
    } catch (error) {
      setError(true);
      addErrorBanner(getString("errors.general"), error);
    }
  };

  /* istanbul ignore next */
  const fetchPreCheckoutData = async (token: string, itemTxId: string) => {
    try {
      const ordersService: IOrdersService = OrdersServiceFactory.getInstance(itemTxId);
      const checkoutData = await ordersService.GetPreCheckoutInformation(itemTxId, token);
      if (!checkoutData.information) {
        throw new Error("Couldn't obtain pre checkout information");
      }
      setPreCheckoutData(checkoutData.information);
    } catch (error) {
      setError(true);
      addErrorBanner(getString("errors.general"), error);
    }
  };

  if (itemData) {
    const productDetailsInfo: DetailsPageProps = {
      id: atn.getTaxonomyId(),
      item: itemData,
      preCheckoutInformation: preCheckoutData,
      fetchPreCheckoutData: fetchPreCheckoutData,
    };

    return (
      <PageContainer paddingHorizontal={0} paddingTop={0} isFullWidth={true}>
        {/* This is where we will inject the breadcrumb links as child of TopBar, rendering a blank space */}
        <TopBar>
          <Text>&nbsp;</Text>
        </TopBar>
        <ProductDetailsPage {...productDetailsInfo}></ProductDetailsPage>
      </PageContainer>
    );
  } else {
    // No data, either loading or not found
    return (
      <PageContainer paddingHorizontal={0} paddingTop={0} isFullWidth={true}>
        <TopBar>
          <Text>&nbsp;</Text>
        </TopBar>
        <Container isFullWidth={false}>
          {!error && (
            <View>
              <Spinner size={SpinnerSize.Medium} fr={undefined} />
            </View>
          )}
        </Container>
      </PageContainer>
    );
  }

  function checkIfExternal() {
    const searchParams = new URLSearchParams(location.search);
    const from = searchParams.get("from");
    if (!from) {
      history.push({
        pathname: location.pathname,
        search: `?from=${TrafficFrom.External}`,
      });
    }
  }
};

export default DetailsPage;
