import "./style.scss";

import { Col, Container, Row, View } from "@amzn/stencil-react-components/layout";
import { Hr } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import RecommendedItemsFactory from "common/factories/recommended-items-factory";
import React, { FunctionComponent } from "react";

import { getString } from "../../uistringlabels/uiStringUtils";

export const requestSoftwareUrl = getString("noResults.requestSoftwareUrl");

export interface NoResultsProps {
  searchTerm?: string | null;
}

export const NoResultsFound: FunctionComponent<NoResultsProps> = ({ searchTerm }: NoResultsProps) => (
  <>
    <Container className="no-results-details" paddingHorizontal="S700" paddingTop="S700" paddingBottom="S500">
      <View>
        <Row key={searchTerm} gridGap="S700" alignItems="center" padding={[0, 0, "S700", 0]}>
          <Col height="100%">
            <Row padding={["S400", 0, 0, 0]}>
              <Text data-details-about="details-about" fontSize="T500" className="text">
                {getString("noResults.noSoftware")} &apos;{searchTerm}&apos;
              </Text>
            </Row>
            <Row padding={["S400", 0, 0, 0]}>
              <Text data-details-about="details-about" fontSize="T300" className="text">
                {getString("noResults.tryOrText")}
              </Text>
              &nbsp;
              <Text data-details-about="details-about" fontSize="T300" className="text">
                &nbsp;
                <a href={requestSoftwareUrl} target="_blank" rel="Request Software noreferrer">
                  {getString("noResults.requestSoftware")}
                </a>
              </Text>
            </Row>
          </Col>
        </Row>

        <Hr color="neutral30" />
      </View>
    </Container>

    <Container className="no-results-details" paddingHorizontal="S700" paddingTop="S300" paddingBottom="S700">
      <View>
        <Row key="popularSoftware" gridGap="S300" alignItems="center">
          <Col height="100%">
            <Row>
              <Text data-details-about="details-about" fontSize="T300" fontWeight="bold">
                {getString("noResults.popularSoftware")}
              </Text>
            </Row>
          </Col>
        </Row>
        <Row key="popularSoftwareItems" gridGap="S300" alignItems="center" className="product-description-box">
          <Col height="100%">
            <Row>
              <RecommendedItemsFactory pageSize={3} categoryToFilterBy="popular"></RecommendedItemsFactory>
            </Row>
          </Col>
        </Row>
      </View>
    </Container>
  </>
);
