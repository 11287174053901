import { Asset } from "@amzn/ito-client";
import { Button, ButtonIconPosition, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import { IconCopy } from "@amzn/stencil-react-components/icons";
import { Container, Row } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FunctionComponent } from "react";

const REGEX_FOR_HTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
export interface AssetDetailProps {
  asset: Asset;
}

export interface PropertDisplayProps {
  name: string;
  value: string;
}

/**
 * Will build a display for the label and value of each property of the details
 */
export const PropertyDisplay: FunctionComponent<PropertDisplayProps> = (props: PropertDisplayProps) => {
  // Looks for a nice label to show
  let label = getString(`asset.labels.${props.name}`);
  if (!label) {
    label = props.name;
  }

  /* istanbul ignore next */
  const onClipboardButtonClick = () => {
    if (props.value) {
      navigator.clipboard.writeText(props.value);
    }
  };

  const isHtmlContent = REGEX_FOR_HTML.test(props.value);

  return (
    <Container paddingTop={10}>
      <Text fontSize="T200">{label}</Text>

      <Row gridGap="S300" alignItems="center">
        {isHtmlContent && (
          <Text fontSize="T100" dataTestId="label">
            <span dangerouslySetInnerHTML={{ __html: props.value }} />
          </Text>
        )}

        {!isHtmlContent && (
          <Text fontSize="T100" dataTestId="value" font="monospace" fontWeight={600}>
            {props.value}
          </Text>
        )}

        {!isHtmlContent && (
          <Button
            icon={<IconCopy aria-hidden={true} />}
            iconPosition={ButtonIconPosition.Trailing}
            size={ButtonSize.Small}
            variant={ButtonVariant.Tertiary}
            onClick={onClipboardButtonClick}
          >
            Copy
          </Button>
        )}
      </Row>
    </Container>
  );
};

export const AssetDetail: FunctionComponent<AssetDetailProps> = (props: AssetDetailProps) => {
  if (!props.asset.details) {
    // No details... return the guid for troubleshooting
    return <Text>{props.asset.assetId}</Text>;
  }

  // Loop on every property of the asset details
  const details: any = props.asset.details;
  const labels = [];
  for (const key in details) {
    if (Object.prototype.hasOwnProperty.call(details, key) && details[key] !== undefined && details[key] !== "") {
      const element = details[key];
      if (element) {
        labels.push(<PropertyDisplay key={key} name={key} value={element} />);
      }
    }
  }

  // To return all elements in one wrapper
  return (
    <>
      {labels.map((element, index) => {
        return element;
      })}
    </>
  );
};
