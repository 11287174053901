import "./style.scss";

import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Checkbox, DetailedRadio, TextArea } from "@amzn/stencil-react-components/form";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { ModalContent } from "@amzn/stencil-react-components/modal";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { Label, Text } from "@amzn/stencil-react-components/text";
import { InlineUserReference } from "common/components/InlineUserReference";
import { CreateOrderRequestContent } from "common/types";
import { PreCheckoutInformation } from "common/types";
import { MessageStruct } from "common/types/ui-types";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FunctionComponent, useEffect } from "react";

import { useSecurityOrderState } from "./security-form-input";
import { SecurityDataForm } from "./SecurityDataForm";

export interface SubmitSecurityOrderModalProps {
  name: string;
  taxonomyId: string;
  submitOrderHandler: (request: CreateOrderRequestContent) => void;
  preCheckoutInformation?: PreCheckoutInformation;
  close: () => void;
  csrfTokenIsLoading: boolean;
  isLoadingCreate: boolean;
  messages?: MessageStruct[];
}

export const SubmitSecurityOrderModal: FunctionComponent<SubmitSecurityOrderModalProps> = ({
  name,
  taxonomyId,
  preCheckoutInformation,
  submitOrderHandler,
  close,
  csrfTokenIsLoading,
  isLoadingCreate,
  messages,
}: SubmitSecurityOrderModalProps) => {
  const { inputs, updateInputs } = useSecurityOrderState();

  useEffect(() => {
    if (inputs.goNext) {
      updateInputs("showForm", false);
    } else {
      updateInputs("showForm", true);
    }
  }, [inputs.goNext]);

  useEffect(() => {
    updateInputs(
      "securitySummary",
      `I already have an approval, URL: <a target="_blank" href="${inputs.securityUrl}" style="color: #1768C9;">${inputs.securityUrl}</a>`
    );
  }, [inputs.securityUrl]);

  const submitOrder = async () => {
    const request: CreateOrderRequestContent = {
      items: {
        [taxonomyId!]: {
          quantity: 1,
          details: {
            softwareOrderDetails: { securitySummary: inputs.securitySummary },
          },
        },
      },
      comment: inputs.comment,
    };
    await submitOrderHandler(request);
  };

  const handleFormSave = (formData: string) => {
    updateInputs("securitySummary", formData);
  };

  const handleFormSubmit = (enableSubmit: boolean) => {
    updateInputs("formIsValid", enableSubmit);
  };

  const canClickSubmmit = () => {
    const securityURLAdded = inputs.securityUrl.trim().length > 5;
    const canAutoApprove = preCheckoutInformation !== undefined && preCheckoutInformation!.canBeAutoApproved;
    const submitSecurityURL = !inputs.showForm && securityURLAdded;
    const submitSecurityForm = inputs.showForm && inputs.formIsValid;
    const hasSecurityData = submitSecurityURL || submitSecurityForm;

    if (!inputs.confirmIsChecked || !inputs.securityConfirmIsChecked || isLoadingCreate) return false;
    if (canAutoApprove && hasSecurityData) return true;
    return inputs.comment != undefined && inputs.comment.trim() && hasSecurityData;
  };

  const canClickNext = () => {
    const hasPreCheckoutData = preCheckoutInformation !== undefined;
    const canAutoApprove = hasPreCheckoutData && preCheckoutInformation!.canBeAutoApproved;
    const hasApproveJustification =
      hasPreCheckoutData && !canAutoApprove && inputs.comment != undefined && inputs.comment.trim();
    if (
      !inputs.confirmIsChecked ||
      !inputs.securityConfirmIsChecked ||
      isLoadingCreate ||
      (!canAutoApprove && !hasApproveJustification)
    )
      return false;
    return true;
  };

  const selectApprovalRequestOption = (
    <Col>
      <Row>
        <Text>{getString("submitSecurityApprovalOrder.approvalSelectionText")}</Text>
      </Row>
      <Row gridGap="S300">
        <DetailedRadio
          data-testid="radio-prev-approval"
          titleText="Already have an approval link"
          checked={inputs.hasApproval}
          onChange={() => updateInputs("hasApproval", true)}
        />
        <DetailedRadio
          data-testid="radio-new-approval"
          titleText="Create an approval request"
          checked={!inputs.hasApproval}
          onChange={() => updateInputs("hasApproval", false)}
        />
      </Row>
    </Col>
  );

  const securityApprovalUrl = (
    <Col padding="L400">
      <Label htmlFor="approval-url-text-area">
        {getString("submitSecurityApprovalOrder.submitApplinkSecurityOrder.approvalURLDescriptionBody")}
      </Label>
      <TextArea
        id="approval-url-text-area"
        data-cy="approval-url-text-area"
        placeholder={getString(
          "submitSecurityApprovalOrder.submitApplinkSecurityOrder.approvalURLDescriptionPlaceholder"
        )}
        value={inputs.securityUrl}
        onChange={(e) => updateInputs("securityUrl", e.target.value)}
      />
    </Col>
  );

  const modalButtons = (
    <div key="action-buttons">
      {inputs.hasApproval || (!inputs.hasApproval && inputs.showForm) ? (
        <Button
          key="submitOrder-submit"
          data-testid="submitOrder-submit"
          data-cy="submit-order-button"
          variant={ButtonVariant.Primary}
          disabled={!canClickSubmmit()}
          onClick={submitOrder}
        >
          Submit
          {isLoadingCreate ? <Spinner size={SpinnerSize.Small} fr={undefined} /> : null}
        </Button>
      ) : (
        <Button
          key="next-button"
          data-testid="next-button"
          data-cy="next-button"
          variant={ButtonVariant.Primary}
          disabled={!canClickNext()}
          onClick={() => updateInputs("goNext", false)}
        >
          Next
        </Button>
      )}
    </div>
  );

  const selectModalBodyComponent = () => {
    if (!preCheckoutInformation || csrfTokenIsLoading) {
      return (
        <>
          <Spinner size={SpinnerSize.Medium} fr={undefined} />
          <br />
        </>
      );
    }

    if (preCheckoutInformation.canBeAutoApproved) {
      return (
        <>
          <Text color="neutral90" fontSize="T300">
            {getString("submitSanctionOrder.autoApproveOrderBody")}
          </Text>
          <br />
        </>
      );
    }

    const requiredApprover = preCheckoutInformation.requiredApprover!;

    return (
      <Col gridGap="S100">
        <Text color="neutral90" fontSize="T200">
          {getString("submitSanctionOrder.managerRequest.body")}
          <strong>
            {" "}
            <InlineUserReference userReference={requiredApprover}></InlineUserReference>{" "}
          </strong>
        </Text>
        <Label htmlFor="comment-text-area">{getString("submitSanctionOrder.managerRequest.textAreaLabel")}</Label>
        <TextArea
          id="comment-text-area"
          data-cy="comment-text-area"
          placeholder={getString("submitSanctionOrder.managerRequest.textAreaPlaceHolder")}
          value={inputs.comment}
          onChange={(e) => updateInputs("comment", e.target.value)}
        />
        <br />
      </Col>
    );
  };

  const modalBodyComponent = selectModalBodyComponent();

  let messagesDisplay = <Text></Text>;

  /* istanbul ignore next */
  if (messages && messages.length > 0) {
    const listItems = messages.map((d) => (
      <MessageBanner key={d.message} type={d.type}>
        {d.message}
      </MessageBanner>
    ));
    messagesDisplay = (
      <div>
        <hr />
        {listItems}
      </div>
    );
  }

  const createModalContent = (
    <>
      <Col gridGap="S400" alignItems="center">
        <Col gridGap="S200" padding="S100">
          {modalBodyComponent}
        </Col>
      </Col>
      <Col gridGap="S400" alignItems="start">
        <Col gridGap="S200" alignSelf="flex-start">
          <MessageBanner type={MessageBannerType.Informational}>
            {getString("submitSecurityApprovalOrder.submitApplinkSecurityOrder.dataClassificationInformation")}
          </MessageBanner>
        </Col>
        <Col gridGap="S200">
          <Row gridGap="S200">
            <Checkbox
              id="checkReadSecurityData"
              data-cy="read-acceptable-use-policy-checkbox"
              checked={inputs.securityConfirmIsChecked}
              onChange={(e: any) => updateInputs("securityConfirmIsChecked", e.target.checked)}
            />
            <Label htmlFor="checkReadSecurityData">
              {getString("submitSecurityApprovalOrder.submitApplinkSecurityOrder.dataClassificationAcknowledgement")}
            </Label>
          </Row>
        </Col>
        <Col gridGap="S200">
          <Row gridGap="S200">
            <Checkbox
              id="checkReadAcceptableUsePolicy"
              data-cy="read-acceptable-use-policy-checkbox"
              checked={inputs.confirmIsChecked}
              onChange={(e: any) => updateInputs("confirmIsChecked", e.target.checked)}
            />
            <Label htmlFor="checkReadAcceptableUsePolicy">
              {getString("submitSanctionOrder.acceptConditions.part1")}
              <Link href={"https://policy.a2z.com/docs/111/publication"} target="_blank">
                {getString("submitSanctionOrder.acceptConditions.part2")}
              </Link>
              {getString("submitSecurityApprovalOrder.acceptConditions.part1")}
              <Link href={"https://policy.a2z.com/docs/863/publication"} target="_blank">
                {getString("submitSecurityApprovalOrder.acceptConditions.part2")}
              </Link>
              {getString("submitSanctionOrder.acceptConditions.part3")}
            </Label>
          </Row>
        </Col>
      </Col>
      <br />
      {messagesDisplay}
      {selectApprovalRequestOption}
      <br />
      {inputs.hasApproval && securityApprovalUrl}
      <Col gridGap="S200" alignSelf="flex-start">
        <Text fontSize="T100" color="neutral70">
          {getString("submitSanctionOrder.requiredField")}
        </Text>
      </Col>
    </>
  );

  return (
    <ModalContent
      titleText={name ? name : ""}
      buttons={[
        <Button key="submitOrder-close" variant={ButtonVariant.Tertiary} onClick={close}>
          Cancel
        </Button>,
        modalButtons,
      ]}
      maxWidth={815}
    >
      {inputs.showForm ? (
        <SecurityDataForm
          onSave={handleFormSave}
          onValidationSubmit={handleFormSubmit}
          softwareName={name ? name : ""}
        />
      ) : (
        createModalContent
      )}
    </ModalContent>
  );
};
