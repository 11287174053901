import { GetItemsResponseContent } from "common/types";
import { CATALOG_PROVIDER, CATALOG_SERVICE_ENDPOINT, ITMPV1_CATALOGICAL_ENDPOINT } from "config";
import { StatusCodes } from "http-status-codes";
import { ItemsHelper } from "lib/helpers";
import { ItemTaxonomyId } from "lib/taxonomy/item-taxonomy-id";

import { LegacyBaseService } from "./legacy-base-service";

export class CatalogService extends LegacyBaseService {
  constructor() {
    if (CATALOG_PROVIDER === "itmpv1-catalogical") {
      super(ITMPV1_CATALOGICAL_ENDPOINT);
    } else {
      super(CATALOG_SERVICE_ENDPOINT);
    }
  }

  public async GetItemsByTaxonomyIdString(
    itemTaxonomyIdString: string,
    nextToken?: string,
    pageSize?: number
  ): Promise<GetItemsResponseContent> {
    return this.GetItems(ItemTaxonomyId.Parse(itemTaxonomyIdString), nextToken, pageSize);
  }

  public async GetItems(
    itemTaxonomyId: ItemTaxonomyId,
    nextToken?: string,
    pageSize?: number
  ): Promise<GetItemsResponseContent> {
    const queryParams = ItemsHelper.getQueryParams({
      taxonomyId: itemTaxonomyId.getUrlSuffix(),
      nextToken: nextToken,
      pageSize: pageSize,
    });

    const urlPrefix = CATALOG_PROVIDER === "itmpv1-catalogical" ? "/items" : "/catalog/items";
    const url = `${urlPrefix}/${itemTaxonomyId.getUrlSuffix()}?${queryParams}`;
    const response: void | Response = await this.fetch(url).catch((err) => {
      console.error(err);
    });

    if (!response) {
      // There was a Sentry error
      window.location.href = "https://midway-auth.amazon.com/login?reauth=1";
      return { totalResults: 0, items: [] };
    }

    if (response.status !== StatusCodes.OK) {
      throw new Error("An error occurred when searching catalog items.");
    }

    const searchResponse = await response.json();
    return searchResponse;
  }
}
