import { SearchRequestContent } from "common/types";
import { SearchResponseContent } from "common/types/search-types";
import { CATALOG_PROVIDER, CATALOG_SERVICE_ENDPOINT, ITMPV1_CATALOGICAL_ENDPOINT } from "config";
import { StatusCodes } from "http-status-codes";
import { SearchHelper } from "lib/helpers";

import { LegacyBaseService } from "./legacy-base-service";

export class SearchService extends LegacyBaseService {
  /**
   * Singleton instance with the current user permissions.
   */
  public static current = new SearchService();

  _queryText: string | undefined;

  constructor() {
    if (CATALOG_PROVIDER === "itmpv1-catalogical") {
      super(ITMPV1_CATALOGICAL_ENDPOINT);
    } else {
      super(CATALOG_SERVICE_ENDPOINT);
    }
  }

  /**
   * Retrieves the query text.
   */
  public get queryText(): string | undefined {
    return this._queryText;
  }

  public async Search(parameters: SearchRequestContent): Promise<SearchResponseContent> {
    this._queryText = parameters.text;
    const queryParams = SearchHelper.getQueryParams(parameters);
    const response = await this.fetch(`/search?${queryParams}`);

    if (response.status !== StatusCodes.OK) {
      throw new Error("An error occurred when searching catalog items.");
    }

    const searchResponse = await response.json();
    return searchResponse;
  }
}
