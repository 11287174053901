import { DetailedRadio, Select } from "@amzn/stencil-react-components/form";
import { Col, GridContainer, GridItem, Spacer } from "@amzn/stencil-react-components/layout";
import { Label } from "@amzn/stencil-react-components/text";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FC, useEffect } from "react";

export interface SelectChildSkuProps {
  versionsChildSkus: any[] | undefined;
  onChange: (value: any) => void;
  selectedSku: any;
}

export const SelectChildSku: FC<SelectChildSkuProps> = ({ versionsChildSkus, onChange, selectedSku }) => {
  useEffect(() => {
    if (versionsChildSkus && versionsChildSkus.length > 0 && !selectedSku) {
      onChange(versionsChildSkus[0]);
    }
  }, [versionsChildSkus, onChange, selectedSku]);

  /* istanbul ignore next */
  const handleRadioChange = (sku: any) => {
    onChange(sku);
  };

  /* istanbul ignore next */
  const handleSelectChange = (selectedValue: string) => {
    const newSelectedSku = versionsChildSkus?.find((sku) => sku.shortDescription === selectedValue);
    if (newSelectedSku) {
      onChange(newSelectedSku);
    }
  };

  const renderRadios = () => {
    if (versionsChildSkus) {
      return versionsChildSkus.map((sku) => (
        <GridItem lg={6} md={4} key={sku.id}>
          <DetailedRadio
            dataTestId="radio-child-sku"
            titleText={sku.shortDescription}
            checked={selectedSku?.id === sku.id}
            onChange={() => handleRadioChange(sku)}
          />
        </GridItem>
      ));
    }
  };

  if (versionsChildSkus && versionsChildSkus.length <= 6) {
    return (
      <Col>
        <Label>
          {selectedSku?.id && selectedSku.id.startsWith("atn::software")
            ? getString("details.selectChildSkuTeam")
            : getString("details.selectChildSku")}
        </Label>
        <Spacer height="S300" />
        <GridContainer>{renderRadios()}</GridContainer>
      </Col>
    );
  } else if (versionsChildSkus && versionsChildSkus.length > 6) {
    const options = versionsChildSkus.map((sku) => sku.shortDescription);
    return (
      <Col gridGap="S200">
        <Label>
          {selectedSku?.id && selectedSku.id.startsWith("atn::software")
            ? getString("details.selectChildSkuTeam")
            : getString("details.selectChildSku")}
        </Label>
        <Select
          dataTestId="select-child-sku"
          onChange={handleSelectChange}
          options={options}
          value={selectedSku?.shortDescription}
          isSearchable={true}
          width="65%"
        />
      </Col>
    );
  } else {
    return null;
  }
};
