import "./style.scss";

import { Col, Row, View } from "@amzn/stencil-react-components/layout";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { TrafficFrom } from "common/helpers/traffic-from";
import { SearchRequestContent, SearchResponseContent } from "common/types";
import { CatalogItemSimplified } from "common/types/catalog-types";
import { getString } from "common/uistringlabels/uiStringUtils";
import { SearchService } from "lib/services";
import React, { FC, useEffect, useState } from "react";

import { CatalogItemCard } from "../CatalogItemCard";
import { useMessageBannersBox } from "../MessageBannersBox/hook";
export interface RecommendedItemsProps {
  pageSize: number;
  tagToFilterBy: string;
  children?: any;
}

const RecommendedItems: FC<RecommendedItemsProps> = (props: RecommendedItemsProps) => {
  const { addErrorBanner } = useMessageBannersBox();
  const [itemsData, setItemsData] = useState<CatalogItemSimplified[] | null>(null);

  /* istanbul ignore next */ // TODO: setup proper unit testing with useEffect hook (To be resolved by ITMP-345)
  // Used to be able to load data async
  useEffect(() => {
    if (!itemsData) {
      fetchData();
    }
  }, []);

  /* istanbul ignore next */
  // Fetch the actual data, calling the catalog API
  const fetchData = async () => {
    const client = new SearchService();
    const searchRequest: SearchRequestContent = {
      pageSize: props.pageSize,
      text: "",
      tags: [props.tagToFilterBy],
    };
    try {
      const response: SearchResponseContent = await client.Search(searchRequest);
      if (!response.items) throw new Error("No item data found");
      setItemsData(response.items);
    } catch (error) {
      setItemsData([]);
      addErrorBanner(getString("errors.general"), error);
    }
  };

  if (itemsData) {
    // Items data is ready, display it
    return (
      <View>
        <Row
          key={`${props.tagToFilterBy}`}
          className={`recommended-items recommended-items-${props.tagToFilterBy}  recommended-items-by-${props.pageSize}`}
        >
          {itemsData.map((item, i) => (
            <Col
              key={i}
              className={`recommended-item recommended-item-${props.tagToFilterBy}`}
              data-cy={`recommended-item-${props.tagToFilterBy}`}
              padding={["S100", "S500", 0, 0]}
            >
              <CatalogItemCard
                from={TrafficFrom.Recommended}
                name={item.name}
                shortDescription={item.shortDescription}
                logoUrl={item.logoImageUrl}
                taxonomyId={item.itemId}
              />
            </Col>
          ))}
        </Row>
        {props.children}
      </View>
    );
  } else {
    // No data, either loading or not found
    return (
      <View alignItems="center">
        <Spinner size={SpinnerSize.Small} fr={undefined} />
      </View>
    );
  }
};

export default RecommendedItems;
