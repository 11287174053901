/* istanbul ignore file */
import { AnyAction, CombinedState, combineReducers, Reducer } from "@reduxjs/toolkit";
import { approvalRequestsLinkReducer, ApprovalRequestsLinkState } from "common/components/ApprovalRequestsLink/reducer";
import { MessageBannerBoxState, messageBannersBoxReducer } from "common/components/MessageBannersBox/reducer";

import { catalogItemsReducer, CatalogItemsState } from "./components/CatalogItemsList";

export interface OldAppState {
  /**
   * State for the catalog items data
   */
  catalogItems: CatalogItemsState;
  /**
   * State for the message banners box data
   */
  messageBannersBox: MessageBannerBoxState;

  /**
   * State for pending orders count
   */
  approvalRequestsLinkState: ApprovalRequestsLinkState;
}

const oldAppReducer: Reducer<CombinedState<OldAppState>, AnyAction> = combineReducers<OldAppState>({
  catalogItems: catalogItemsReducer,
  messageBannersBox: messageBannersBoxReducer,
  approvalRequestsLinkState: approvalRequestsLinkReducer,
});

export default oldAppReducer;
