import { Container, View } from "@amzn/stencil-react-components/layout";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { Text } from "@amzn/stencil-react-components/text";
import { TopBar } from "common/components";
import { CatalogicalProductDetailsPage } from "common/components/CatalogicalProductDetailsPage";
import { useMessageBannersBox } from "common/components/MessageBannersBox/hook";
import { TrafficFrom } from "common/helpers/traffic-from";
import { PreCheckoutInformation } from "common/types";
import { getString } from "common/uistringlabels/uiStringUtils";
import { CatalogicalService } from "lib/services/catalogical-service";
import { IOrdersService, OrdersServiceFactory } from "lib/services/orders";
import { ItemTaxonomyId } from "lib/taxonomy/item-taxonomy-id";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { CatalogicalItem } from "../../../common/types/catalogical-type";

export interface CatalogicalDetailsPageProps {
  id?: string;
  item: CatalogicalItem;
  preCheckoutInformation?: PreCheckoutInformation;
  fetchPreCheckoutData?: (token: string) => void;
}

const CatalogicalDetailsPage: FC<CatalogicalDetailsPageProps> = (props: CatalogicalDetailsPageProps) => {
  const history = useHistory();
  // Gets the Taxonomy parts from the url location
  const location = useLocation();
  const taxonomyUrl = location.pathname.replace("/items/", "");

  checkIfExternal();

  const atn = ItemTaxonomyId.ParseWithoutTypePrefix(taxonomyUrl);

  const { addErrorBanner } = useMessageBannersBox();

  const [preCheckoutData, setPreCheckoutData] = useState<PreCheckoutInformation | undefined>(undefined);
  const [itemData, setItemData] = useState<CatalogicalItem | undefined>(props.item);
  const [error, setError] = useState(false);

  // Used to be able to load data async
  useEffect(() => {
    fetchItemData();
  }, [taxonomyUrl]);

  /* istanbul ignore next */
  // Fetch the actual data, calling the catalogical API
  const fetchItemData = async () => {
    const client = new CatalogicalService();
    try {
      const response = await client.getCatalogItemByTaxonomyId(atn.getTaxonomyId());
      if (!response || !response.item) {
        throw new Error("No item data found");
      }
      setItemData(response.item);
      setPreCheckoutData(undefined);
    } catch (error) {
      error as Error;
      setError(true);
      addErrorBanner(getString("errors.general"), Error.toString());
    }
  };

  /* istanbul ignore next */
  const fetchPreCheckoutData = async (token: string) => {
    try {
      const taxonomyIdWithoutStrain = ItemTaxonomyId.Parse(itemData!.taxonomyId).getTaxonomyIdWithoutStrain();
      const ordersService: IOrdersService = OrdersServiceFactory.getInstance(taxonomyIdWithoutStrain);
      const checkoutData = await ordersService.GetPreCheckoutInformation(taxonomyIdWithoutStrain, token);
      if (!checkoutData.information) {
        throw new Error("Couldn't obtain pre checkout information");
      }
      setPreCheckoutData(checkoutData.information);
    } catch (error) {
      setError(true);
      addErrorBanner(getString("errors.general"), error);
    }
  };
  if (itemData) {
    const productDetailsInfo: CatalogicalDetailsPageProps = {
      id: atn.getTaxonomyId(),
      item: itemData,
      preCheckoutInformation: preCheckoutData,
      fetchPreCheckoutData: fetchPreCheckoutData,
    };

    return (
      <PageContainer paddingHorizontal={0} paddingTop={0} isFullWidth={true}>
        {/* This is where we will inject the breadcrumb links as child of TopBar, rendering a blank space */}
        <TopBar>
          <Text>&nbsp;</Text>
        </TopBar>
        <CatalogicalProductDetailsPage {...productDetailsInfo}></CatalogicalProductDetailsPage>
      </PageContainer>
    );
  } else {
    // No data, either loading or not found
    return (
      <PageContainer paddingHorizontal={0} paddingTop={0} isFullWidth={true}>
        <TopBar>
          <Text>&nbsp;</Text>
        </TopBar>
        <Container isFullWidth={false}>
          {!error && (
            <View>
              <Spinner size={SpinnerSize.Medium} fr={undefined} />
            </View>
          )}
        </Container>
      </PageContainer>
    );
  }

  function checkIfExternal() {
    const searchParams = new URLSearchParams(location.search);
    const from = searchParams.get("from");
    if (!from) {
      history.push({
        pathname: location.pathname,
        search: `?from=${TrafficFrom.External}`,
      });
    }
  }
};

export default CatalogicalDetailsPage;
