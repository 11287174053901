import CatalogicalRecommendedItems from "common/components/CatalogicalRecommendedItems";
import RecommendedItems from "common/components/RecommendedItems";
import { CATALOG_PROVIDER } from "config";
import React, { FC } from "react";

export interface IRecommendedItemsProps {
  pageSize: number;
  categoryToFilterBy: string;
  children?: any;
}

const mappingToTags: { [key: string]: string } = {
  popular: "Popular",
  essential: "Recommended",
};

function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

const RecommendedItemsFactory: FC<IRecommendedItemsProps> = (props: IRecommendedItemsProps) => {
  if (CATALOG_PROVIDER === "catalogical") {
    return (
      <CatalogicalRecommendedItems pageSize={props.pageSize} categoryToFilterBy={props.categoryToFilterBy}>
        {props.children}
      </CatalogicalRecommendedItems>
    );
  } else {
    return (
      <RecommendedItems pageSize={props.pageSize} tagToFilterBy={mappingToTags[props.categoryToFilterBy]}>
        {props.children}
      </RecommendedItems>
    );
  }
};

export default RecommendedItemsFactory;
