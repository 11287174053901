/* istanbul ignore file */
/**
 * This file is autogenerated by the CatalinaV2Spec package
 **/

export interface CatalogItem {
  /**
   * Item order workflow
   */
  orderWorkflow?: string;
  /**
   * Unique taxonomy identifier of the product.
   */
  itemId: string | undefined;

  /**
   * The product's name
   */
  name: string | undefined;

  /**
   * The product's description
   */
  description: string | undefined;

  /**
   * A short description for the product
   */
  shortDescription: string | undefined;

  /**
   * The product manufacturer
   */
  manufacturer?: string;

  /**
   * Logo URL of the product
   */
  logoImageUrl: string | undefined;

  /**
   * image URLs of the product
   */
  imageUrls: string[] | undefined;

  /**
   * Categories associated to the product
   */
  categories: string[] | undefined;

  /**
   * Versions of the product
   */
  versions: Version[] | undefined;

  /**
   * The tags associated to the product
   */
  tags?: string[];

  /**
   * The supported OS for the product
   */
  os?: string[];

  /**
   * The set of links and references for the product
   */
  references?: References;

  /**
   * The product status
   */
  status: string;

  etaDays: number;

  hasStock: boolean;
}

export interface CatalogItemSimplified {
  /**
   * Unique taxonomy identifier of the product.
   */
  itemId: string | undefined;

  /**
   * The product's name
   */
  name: string | undefined;

  /**
   * A short description for the product
   */
  shortDescription: string | undefined;

  /**
   * The product manufacturer
   */
  manufacturer?: string;

  /**
   * Logo URL of the product
   */
  logoImageUrl: string | undefined;

  /**
   * Categories associated to the product
   */
  categories: string[] | undefined;

  /**
   * The tags associated to the product
   */
  tags?: string[];

  /**
   * The supported OS for the product
   */
  os?: string[];

  /**
   * The product status
   */
  status: string;

  etaDays: number;

  hasStock: boolean;
}

export interface References {
  /**
   * Used to redirect the user here instead of the normal ordering flow
   */
  orderRedirectURL?: string;

  /**
   * If present, gives more details about the product status. Usually for Unsactioned or Unavailable products
   */
  statusMoreDetailsURL?: string;
}

export interface Version {
  name?: string;
  /**
   * Version identifier (from taxonomy ID)
   */
  id: string | undefined;

  /**
   * Price of the product
   */
  price: number | undefined;

  /**
   * The recurrence at which the product must be re-purchased (can be parsed with https://momentjs.com/docs/#/durations/)
   */
  recurrence?: string;

  /**
   * Currency code (ISO-4217)
   */
  currency: string | undefined;

  /**
   * The status of the product (sanctioned, unsanctioned or unavailable)
   */
  status: ItemStatus | string | undefined;

  /**
   * The item short description
   */
  shortDescription?: string;
}

export enum ItemStatus {
  sanctioned = "sanctioned",
  unavailable = "unavailable",
  unsanctioned = "unsanctioned",
}
