// This regexp method is to decode html encoded URLs, it replaces the encoding with ampersands and slashes
export const decodeHtmlEntity = (encodedEntity: string = "") => {
  return encodedEntity
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
    .replace(/&#39;/g, "'")
    .replace(/&nbsp;/g, " ")
    .replace(/&#x2F;/g, "/")
    .replace(/&#x27;/g, "'")
    .replace(/&#x22;/g, '"')
    .replace(/&#x3C;/g, "<")
    .replace(/&#x3E;/g, ">")
    .replace(/&#47;/g, "/")
    .replace(/&#39;/g, "'")
    .replace(/&#34;/g, '"')
    .replace(/&#60;/g, "<")
    .replace(/&#62;/g, ">")
    .replace(/&amp;nbsp;/g, " ")
    .replace(/&#(\d+);/g, (match, numStr) => String.fromCharCode(parseInt(numStr, 10)))
    .replace(/&#x([a-fA-F0-9]+);/g, (match, hexStr) => String.fromCharCode(parseInt(hexStr, 16)));
};

export const decodeHtmlEntityDeep = (value: any): any => {
  // Use the decodeHtmlEntity function for string values
  if (typeof value === "string") {
    return decodeHtmlEntity(value);
  }
  // If it's an array, decode each element
  if (Array.isArray(value)) {
    return value.map(decodeHtmlEntityDeep);
  }
  // If it's an object, decode each property
  if (typeof value === "object" && value !== null) {
    Object.keys(value).forEach((key) => {
      value[key] = decodeHtmlEntityDeep(value[key]);
    });
    return value;
  }
  return value;
};
